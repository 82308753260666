var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row position-relative w-100 m-0 p-0",staticStyle:{"z-index":"100"},style:(_vm.getPageDesign())},[(_vm.pageDesign.pageStyles.imageAlign == 'no')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),staticClass:"bg lazyload",style:(_vm.getbackgroundImage(
_vm.IsImageAdded,
_vm.pageDesign.imageBlock,
_vm.pageDesign.pageStyles.imageAlign , _vm.isDesktopView
) + _vm.getBgDivStyles()),attrs:{"data-bg":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView))}}):_vm._e(),(_vm.showAlert)?_c('div',{staticClass:"AlertContainer"},[_c('div',{staticClass:"alert text-center fade show text-white alert-text",staticStyle:{"background":"#f27367","width":"fit-content"},attrs:{"role":"alert"}},[_c('span',[_vm._v(" "+_vm._s(_vm.alertMsg)+" ")])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"px-0",class:[
        {
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division col-6': _vm.isDesktopView,
        },
      ],style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{class:[
              `pic-BaldenStep1`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView)),"alt":""}})],1)])]),_c('div',{class:[!_vm.IsImageAdded ? 'col-12 pr-4 pr-md-0' : _vm.imageAddedLayout],style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
        'height:630px!important; scroll:auto;' +
        _vm.calculateContentWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive,
          _vm.pageDesign.pageStyles
        ))},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-end","height":"100%"}},[_c('div',{staticClass:"h-100",staticStyle:{"overflow":"auto"}},_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                  _vm.getPaddingCss(pro.style) +
                  _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                  _vm.getColorWithOpacity(pro.style) +
                  _vm.getTextRotate(pro.style) +
                  _vm.getLineHeight(pro.style) +
                  _vm.getTextAlign(pro.style) +
                  _vm.getFontStyle(pro.style)),domProps:{"innerHTML":_vm._s(_vm.getTextValue(_vm.translateCustomText(pro.content) ))}}):_vm._e(),(pro.type == 'freeText')?_c('div',{staticClass:"px-2 px-md-0"},[_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                    _vm.getTextAlign(pro.style))},[(pro.showLabel)?_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"m-0 p-0 text-left font-weight-bold"},[_vm._v(" "+_vm._s(_vm.translateCustomText( pro.labelText))+" "),(pro.isRequired)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]):_vm._e(),_c('div',{style:(pro.style.borderType === 'border'
                        ? _vm.getInputBorderCSS(pro.style)
                        : _vm.getInputBorderBottomCSS(pro.style))},[((pro.selectedInfoType.type.toLowerCase())==='checkbox'&&(
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName !== 'phone_number'
                      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createTitle),expression:"createTitle"}],staticClass:"text-dark font-weight-medium customBaldenInput CustomTextInput",style:(_vm.customInputStyles(pro.style)),attrs:{"placeholder":_vm.translateCustomText( pro.InfoTypePlaceholder),"required":pro.isRequired,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.createTitle)?_vm._i(_vm.createTitle,null)>-1:(_vm.createTitle)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.elthInitiateOtp(pro.selectedInfoType.type)},"change":function($event){var $$a=_vm.createTitle,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.createTitle=$$a.concat([$$v]))}else{$$i>-1&&(_vm.createTitle=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.createTitle=$$c}}}}):((pro.selectedInfoType.type.toLowerCase())==='radio'&&(
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName !== 'phone_number'
                      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createTitle),expression:"createTitle"}],staticClass:"text-dark font-weight-medium customBaldenInput CustomTextInput",style:(_vm.customInputStyles(pro.style)),attrs:{"placeholder":_vm.translateCustomText( pro.InfoTypePlaceholder),"required":pro.isRequired,"type":"radio"},domProps:{"checked":_vm._q(_vm.createTitle,null)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.elthInitiateOtp(pro.selectedInfoType.type)},"change":function($event){_vm.createTitle=null}}}):(
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName !== 'phone_number'
                      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createTitle),expression:"createTitle"}],staticClass:"text-dark font-weight-medium customBaldenInput CustomTextInput",style:(_vm.customInputStyles(pro.style)),attrs:{"placeholder":_vm.translateCustomText( pro.InfoTypePlaceholder),"required":pro.isRequired,"type":pro.selectedInfoType.type.toLowerCase()},domProps:{"value":(_vm.createTitle)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.elthInitiateOtp(pro.selectedInfoType.type)},"input":function($event){if($event.target.composing)return;_vm.createTitle=$event.target.value}}}):_vm._e()])])]):_vm._e(),(pro.type == 'freeTextArea')?_c('div',{staticClass:"px-2 px-md-0"},[_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                    _vm.getTextAlign(pro.style))},[(pro.showLabel)?_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"m-0 p-0 text-left font-weight-bold"},[_vm._v(" "+_vm._s(_vm.translateCustomText( pro.labelText ))+" "),(pro.isRequired)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"bg-white p-2",style:(pro.style.borderType === 'border'
                        ? _vm.getInputBorderCSS(pro.style)
                        : _vm.getInputBorderBottomCSS(pro.style))},[_c('Editor',{staticClass:"customBaldenTextAreaInput customBaldenInput p-1",attrs:{"productDescription":pro.LeadTextValue,"placeholder":_vm.translateCustomText( pro.InfoTypePlaceholder)},on:{"updateDescription":function($event){return _vm.updateDescription($event)}}})],1)])]):_vm._e()])])}),0),_c('div',[_c('div',{staticClass:"content",style:(_vm.getHeightWidth(_vm.StaticButtonElement.style))},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex mt-2",style:(_vm.getBackNextButtonPosition(
                    _vm.StaticButtonElement,
                    _vm.CurrentPage.value
                  ))},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                    !_vm.StaticButtonElement.content.backButton.hideBackButton
                  ),expression:"\n                    !StaticButtonElement.content.backButton.hideBackButton\n                  "}],staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                      _vm.StaticButtonElement.style,
                      _vm.isDesktopView
                    ) +
                    _vm.getBackNextButtonCss(
                      _vm.StaticButtonElement.content.backButton.style
                    )),on:{"click":_vm.backPage}},[_c('span',{style:({
                      opacity: `${_vm.StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${_vm.StaticButtonElement.content.backButton.style.fontSize}px`,
                    })},[_vm._v(_vm._s(_vm.translateCustomText( _vm.StaticButtonElement.content.backButton.text )))])]),_c('button',{staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                      _vm.StaticButtonElement.style,
                      _vm.isDesktopView
                    ) +
                    _vm.getBackNextButtonCss(
                      _vm.StaticButtonElement.content.nextButton.style
                    )),on:{"click":function($event){return _vm.nextPage()}}},[(!_vm.nextBtnSpinner)?_c('span',{style:({
                      opacity: `${_vm.StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${_vm.StaticButtonElement.content.nextButton.style.fontSize}px`,
                    })},[_vm._v(_vm._s(_vm.translateCustomText( _vm.StaticButtonElement.content.nextButton.text )))]):_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"px-0",class:[
        {
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division col-6': _vm.isDesktopView,
        },
      ],style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView) + `height:103%;`)},[_c('LazyImage',{class:[
              `pic-BaldenRightImage`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
              'height:650px!important;'),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView)),"alt":""}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }